.qbist_holder {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

}

.qbist_tool {
    padding: 2rem;
    padding-top: 0;
    background: black;

    border-radius: 7px;

    position: relative;

}

.metadata_title {
    font-weight: bold;
    font-size: 30px;

    margin-top: 15px;
}

.leftright {
    display: flex;
    background-color: #030303;
    
    border-radius: 7px;
}

.options {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 2.5%;
    padding: 1.5rem;
    padding-bottom: 0;
    background: #1a1a1a;

    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.options > p {
    color: white;
}

.qbist_grid {
    width: 100%; 
    height: 100%;

    max-width: 100%;

    display: grid;

    grid-template-columns: repeat(4, 150px);
    grid-template-rows: repeat(3, 150px);
}

.grid_item {
    background-color: white;

    margin: 20px;
    
    aspect-ratio: 1 / 1;
    
    cursor: pointer;
}


.bottom {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem;
}

.bottom > p {
    margin: 0;
}


.render_view_holder {
    position: absolute;
    top: 0; 
    left: 0;
}

.render_view {
    display: flex;
    position: relative;
}

