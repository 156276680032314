.menu_wrapper {
    position: absolute;
    top: 0;
    left: 0;

    width: 30%;
    height: 100%;

    display: flex;
    justify-content: center;
}

.menu_holder {
    width: 80%;
    height: 100%; 

    display: flex;
    flex-direction: column;
    align-items: left;
}


.title {
    width: 100%;

    height: auto;

    display: flex;
    justify-content: center;
}

.letter {
    font-family: Linebeam;
    opacity: 0;
    

    text-align: center;

    transition: opacity 0.5s;
    

    color: #E71D36;

    font-size: 60px;

    font-weight: 600;
    float: left;
    white-space: pre;
}


.socials {
    width: 100%;
    height: 8%;

    display: flex;
    justify-content: center;
}



.menu {
    display: flex;
    flex-direction: column;
    width: 100%;

    transition: opacity 500ms;
}

.menu_title {
    font-weight: bold;
    font-size: 20px;
}

.link_options {
    display: flex;
    flex-direction: column;
}

.menu_option {
    cursor: pointer;
    width: fit-content;
    font-size: 18px;
    position: relative;

    height: 30px;
    width: 100%;

    display: flex;
    align-items: center;
    

}

.menu_option:hover .menu_option_image {
    filter: grayscale(0);
}


.menu_option_image {
    position: absolute;
    top: 0;
    z-index: 1;
    filter: grayscale(1);

    transition: filter 400ms;

    height: 100%;
    width: 100%;
    object-fit: cover;

}


.option_text {
    z-index: 100;
    position: relative;
    padding-left: 5px;
    height: 100%;
    width: 25%;

    backdrop-filter: blur(1px);

    display: flex;
    align-items: center;
    justify-content: center;
    
}



.option_text > p {
    color: black;
    font-weight: bold;
    font-size: 20px;


}

.menu_option_image_selected {
    position: absolute;
    top: 0;
    z-index: 1;


    height: 100%;
    width: 100%;
    object-fit: cover;
}
