.holder {
    width: 100%;
    height: 100%;
}

.intro_helper_holder {
    position: absolute;
    
    height: 5%;

    right: 0;
    bottom: 0;
}


.intro_helper {
    background-color: rgba(17, 17, 17, 0.35);

    width: 100%;
    height: 100%;

    color: rgb(192, 0, 0);

    display: flex;
    align-items: center;
    justify-content: center;

    border-top-left-radius: 7px;
}


.overlay {
    position: absolute;
    left: 30%;
    height: 100%;
    top: 0;

    background-color: rgb(32, 32, 32);

    width: 70%;
}

.overlay_mobile {
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;

    background-color: rgb(32, 32, 32);

    z-index: 1034043;

    width: 100%;
}


.content_side {
    width: 70%;
    height: 100%;
    position: absolute;
    top: 0; 
    left: 30%;

    
}