body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Monospace',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Quivira";
  src: local("Quivira"),
   url("./fonts/Quivira.otf");
  }

@font-face {
    font-family: "Linebeam";
    src: local("Linebeam"),
     url("./fonts/Linebeam.ttf");
}
    
@font-face {
  font-family: "Lato";
  src: local("Lato"),
  url("./fonts/Lato-Black.ttf")
}