body {
  margin: 0; 
  padding: 0;
}

p {
  margin: 0;
  color: white;
}


.social_holder {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60%;

  position: relative;

}

.social_img {
  width: 35px; 
  height: 35px; 
  display: flex;
  
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0%;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
    opacity: 100%;
  }
}

.number_wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 55%;
  height: 55%;
  top: 0%;
  left: 0%;

  border-radius: 50%;

  border: 1px solid white;

  background: rgb(32, 32, 32);
  z-index: 10;

  animation: fadeInScale;
  animation-iteration-count: 1;
  animation-duration: 0.3s;

  opacity: 0;
}

.number {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  font-size: 18px;
  text-align: center;


}

.cursor_wrapper {
  width: 100%;
  height: 100%;

  position: absolute;

  top: 100%;

  display: flex;
  align-items: top;
  margin-top: 10px;

}

.show_arrows {
  animation: cursorPopIn 1s 1;
  opacity: 0;
}


@keyframes cursorPopIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.arrow_a {
  fill: blue;
  width: 15px;
  position: absolute;

}

.arrow_b {
  fill: red;
  width: 15px;
  position: absolute;
}


@keyframes cursorPopInReverse {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.hide_arrows { 
  animation: cursorPopInReverse 1s 1; 
  animation-delay: 1s;

  opacity: 0;
}

.fade-in {
  animation: fadeIn 1s 1;
}