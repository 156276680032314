@keyframes up {
    0% {
        transform: translateY(200%)
    }
    84% {
        transform: translateY(0);
    }
}

@keyframes shaking {
    0% {
        transform: translateX(3px)
    }
    50% {
        transform: translateX(6px);
    }
    100% {
        transform: translateX(-3px);
    }
}

.tree-up {
    animation: up 1s 1;
}

.tree-shaking {
    animation: shaking 100ms infinite linear;
}


@keyframes flapl {
    0% {
        transform: rotateY(25deg);
        transform: rotateZ(10deg);
    }
    50% {
        transform: rotateY(-15deg);
        transform: rotateZ(-13deg);
    }
    100% {
        transform: rotateY(25deg);
        transform: rotateZ(11deg);
    }
}


@keyframes flapr {
    0% {
        transform: rotateY(-25deg);
        transform: rotateZ(-10deg);
    }
    50% {
        transform: rotateY(15deg);
        transform: rotateZ(13deg);
    }
    100% {
        transform: rotateY(-25deg);
        transform: rotateZ(-11deg);
    }
}

@keyframes bhover {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(3px)
    }
    100% {
        transform: translateY(-1px)
    }
}




.butterfly_static {
    animation: bhover 1s infinite ease-in-out;
}




.preButterfly {
    width: fit-content;
    display: inline-block;

    will-change: translate;
}


.butterfly {
    display: inline-block;
    position: absolute;

    will-change: translate;
}

.butterfly-no-anim {
    display: inline-block;
    position: absolute;
}


@keyframes fadeout {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}



.out [name="hideme"] {
    opacity: 0;
    animation: fadeout 300ms 1;
}

.out {
    animation: none;
    opacity: 1;
}


.treeup {
    animation: up 1.2s 1;
}

.treeshake {
    animation: shaking 100ms infinite linear;
}


.landing_holder {
    width: 100%;
    height: 100%;
}